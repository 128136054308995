import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import jwtDefaultConfig from '../@core/auth/jwt/jwtDefaultConfig';

const env = process.env.NODE_ENV;

const URI =
  env === 'development'
    ? process.env.REACT_APP_DEVELOPMENT_GRAPHQL_URL
    : process.env.REACT_APP_PRODUCTION_GRAPHQL_URL;

const httpLink = createHttpLink({
  uri: URI
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(jwtDefaultConfig.storageTokenKeyName);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
