import mock from '../mock';

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'analyticsDash',
        isBookmarked: false,
        title: 'Analytics Dashboard',
        icon: 'Home',
        link: '/dashboard/analytics'
      },
      {
        id: 2,
        target: 'eCommerceDash',
        isBookmarked: false,
        title: 'eCommerce Dashboard',
        icon: 'Home',
        link: '/dashboard/ecommerce'
      },
      {
        id: 15,
        target: 'userList',
        isBookmarked: false,
        title: 'User List',
        icon: 'User',
        link: '/apps/user/list'
      },
      {
        id: 16,
        target: 'userView',
        isBookmarked: false,
        title: 'User View',
        icon: 'User',
        link: '/apps/user/view'
      }
    ]
  },
  {
    groupTitle: 'Files',
    searchLimit: 4,
    data: []
    // {
    //   title: 'Passport Image',
    //   by: 'Oliver Queen',
    //   size: '52kb',
    //   file: require('@src/assets/images/icons/jpg.png').default
    // }
  },
  {
    groupTitle: 'Contacts',
    searchLimit: 4,
    data: [
      {
        title: 'User Example',
        email: 'example@crizz.com.co',
        img: require('@src/assets/images/portrait/small/avatar-s-8.jpg')
          .default,
        date: '17/06/2022'
      }
    ]
  }
];

// GET Search Data
mock.onGet('/api/main-search/data').reply(() => {
  return [200, { searchArr }];
});

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(() => {
  const bookmarks = searchArr[0].data.filter((item) => item.isBookmarked);
  const suggestions = searchArr[0].data;
  return [200, { suggestions, bookmarks }];
});

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply((config) => {
  const { id } = JSON.parse(config.data);

  const obj = searchArr[0].data.find((item) => item.id === id);

  Object.assign(obj, { isBookmarked: !obj.isBookmarked });

  return [200];
});
